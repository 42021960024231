import { v4 as uuid } from "uuid";
import { useCallback, useEffect, useState } from "react";
import { useRandomName } from "./useRandomName";

const randomId = uuid();

export function useLoadUser() {
  const { name: randomName } = useRandomName();

  const [name, setName] = useState(localStorage.getItem("name") || randomName);
  const [id, setId] = useState(localStorage.getItem("id") || randomId);

  const saveName = useCallback((name: string) => {
    setName(name);
    localStorage.setItem("name", name);
  }, []);

  useEffect(() => {
    const storedId = localStorage.getItem("id");
    if (!storedId) {
      setId(randomId);
      localStorage.setItem("id", randomId);
    }

    const storedName = localStorage.getItem("name");
    if (!storedName) {
      saveName(randomName);
    }
  }, [saveName, randomName]);

  return { id, name, saveName };
}
