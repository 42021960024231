import React from 'react';
import { Link } from 'react-router-dom';
import { useGame } from '../hooks/useGame';
import { useLoadUser } from '../hooks/useLoadUser';
import logo from '../logo.svg';

export const Head: React.FC = () => {
  const { id } = useLoadUser();
  const { leaveRoom } = useGame()
  return (
    <header className="flex flex-row sm:justify-center grow-0 sm:p-2 xs:justify-start">
      <Link className="" to="/" onClick={() => leaveRoom(id)}>
        <img src={logo} alt="Promt Theory" className="xs:h-10 sm:h-12 text-xl text-orange-600 font-bold" />
      </Link>
    </header>
  );
};
