import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { Home } from './Home';
import { Page404 } from './Page404';
import { Game } from './Game';
import { Head } from './Head';
import { Footer } from './Footer';
import { Main } from './Main';

export const App: React.FC = () => {
  return (
    <div className="flex flex-col max-h-screen h-screen bg-neutral-100">
      <Routes>
        <Route path="/" element={<Main><Home /></Main>} />
        <Route path="/game/:roomId" element={<Main><Game /></Main>} />
        <Route path='*' element={
          <>
            <Head />
            <Main>
              <Page404 />
            </Main>
          </>
        } />
      </Routes>
      <Footer />
    </div >
  );
};
