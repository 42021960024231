import { useEffect, useState } from "react";
import { listPublicRooms, PublicRoom } from "../api/firebase";

export function usePublicRooms() {
  const [loading, setLoading] = useState(false);
  const [rooms, setRooms] = useState<PublicRoom[]>([]);

  useEffect(() => {
    setLoading(true);
    const turnOff = listPublicRooms((rooms) => {
      setRooms(rooms);
      setLoading(false);
    });
    return turnOff;
  }, []);

  return { rooms, loading };
}
