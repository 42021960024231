import { useCallback } from "react";

export function useCopyToClipboard() {
  const copyToClipboard = useCallback(
    (content: string, callback?: Function) => {
      function fallbackCopyTextToClipboard(text: string) {
        var textArea = document.createElement("textarea");
        textArea.value = text;

        // Avoid scrolling to bottom
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
          document.execCommand("copy");
        } catch (err) {
          console.log("Fallback: Oops, unable to copy", err);
        }

        document.body.removeChild(textArea);
      }
      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(content);
        return;
      }
      navigator.clipboard.writeText(content).then(
        () => {},
        function (err) {
          console.log("Async: Could not copy text: ", err);
        }
      );

      callback && callback();
    },
    []
  );

  return copyToClipboard;
}
