import { useCallback, useEffect, useState } from "react";
import { Guesses, Player, Room } from "../api/firebase";

export function usePlayersList(room?: Room) {
  const [players, setPlayers] = useState<Player[]>([]);

  const sortPlayersForRound = useCallback(
    (round: number): Player[] => {
      const roundScore = players.map<Player>((p) => {
        if (!p?.guesses || !p.guesses?.[round]) {
          const guesses: Guesses = {
            [round]: {
              input: "",
              score: 0
            }
          };
          p.guesses = guesses;
        }
        return p;
      });

      return roundScore.sort((playerA, playerB) => {
        return playerB.guesses[round].score - playerA.guesses[round].score;
      });
    },
    [players]
  );

  const sortPlayersForGame = useCallback(
    (players: Player[]): Required<Player>[] => {
      const playersTotalScore = players.map((player) => {
        if (!player.guesses) {
          return {
            ...player,
            totalScore: 0
          };
        }

        const totalScore = Object.values(player.guesses).reduce(
          (total, guess) => total + guess.score,
          0
        );
        return {
          ...player,
          totalScore
        };
      });
      return playersTotalScore.sort((playerA, playerB) => {
        return playerB.totalScore - playerA.totalScore;
      });
    },
    []
  );

  useEffect(() => {
    if (!room?.players) return;

    const mappedPlayers = Object.entries(room.players).map(([key, value]) => ({
      id: key,
      ...value
    }));
    setPlayers(mappedPlayers);
  }, [room]);

  return { players, sortPlayersForRound, sortPlayersForGame };
}
