import React, { useCallback, useState } from "react";
import { Navigate } from "react-router-dom";
import { ConfirmButton } from "./ConfirmButton";
import logo from '../logo.svg';
import { useGame } from '../hooks/useGame';

export const Home: React.FC = () => {
  const { publicRooms, userId, name, saveName, room, createRoom, joinRoom, loading, errorMessage } = useGame();

  const [username, setUsername] = useState(name);
  const [publicRoom, setPublicRoom] = useState(true);
  const [editUsernameMode, setEditUsernameMode] = useState(false);

  const handleChangeUsername = useCallback(() => {
    if (username.length < 1) return;

    saveName(username)
    setEditUsernameMode(false);
  }, [saveName, username]);

  const handleCreateClick = useCallback(() => {
    createRoom({ isPublic: publicRoom, userId, name: username, rounds: 10 })
  }, [createRoom, publicRoom, userId, username]);

  const handleJoinRoom = useCallback((roomId: string) => {
    joinRoom({ roomId, userId, name: username });
  }, [joinRoom, userId, username]);

  const isCreateRoomButtonDisabled = loading || username.length < 1;
  if (room && !errorMessage) {
    return <Navigate to={`/game/${room.id}`} />
  }

  return (
    <div id="home" className="flex flex-col justify-center grow">
      <div id="hero" className="flex flex-col justify-center mb-16">
        <div className="w-full flex justify-center mb-8">
          <img src={logo} alt="Promt Theory" className="text-6xl sm:text-7xl text-orange-600 font-bold text-center w-3/4 max-w-3xl" />
        </div>
        <div
          id="subtitle"
          className="flex justify-center text-xl text-neutral-600 font-light text-center"
        >
          Guess the generated image - Play it with friends or solo
        </div>
      </div>
      <div
        id="join-links"
        className="flex flex-col bg-white shadow-sm w-auto rounded-md max-w-full min-w-[350px] mx-auto py-4 px-2 xs:w-[90%] sm:w-fit"
      >
        <div className="mb-4 px-6">
          {editUsernameMode ?
            (<div className="flex flex-row flex-nowrap">
              <input
                autoFocus
                type="text"
                maxLength={20}
                className="border-2 mr-4 rounded-md p-2 text-center shrink"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") handleChangeUsername();
                }}
              />
              <ConfirmButton
                classNames="w-32"
                disabled={username.length < 1}
                onClick={handleChangeUsername}
                label="Confirm"
              />
            </div>) : (
              <div className="flex flex-row flex-nowrap mb-2 gap-2 justify-between items-center">
                <span className="w-2/5">Hi <strong>{username}</strong></span>
                <ConfirmButton
                  onClick={() => setEditUsernameMode(true)}
                  label="Change username"
                  classNames="w-3/5"
                />
              </div>
            )
          }
        </div>
        {errorMessage && (
          <div className="text-center mt-2">
            <span className="text-red-500">{errorMessage}</span>
          </div>
        )}
        <div className="px-6">
          <div className="flex flex-row flex-nowrap justify-between items-center">
            <label className="inline-flex relative items-center cursor-pointer w-2/5">
              <input type="checkbox" checked={publicRoom} onChange={() => setPublicRoom(value => !value)} className="sr-only peer" />
              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600"></div>
              <span className="ml-3 text-sm font-medium text-gray-900">Public</span>
            </label>
            <ConfirmButton
              disabled={isCreateRoomButtonDisabled}
              onClick={handleCreateClick}
              classNames="w-3/5"
              label={loading ? "Creating..." : "Create Room"}
            />
          </div>
          <div className="my-2 text-center"><strong>OR</strong> Join a game</div>
        </div>
        {!!publicRooms.length && <div className="max-h-[250px] overflow-auto px-6">
          <div className="my-2">Open Rooms:</div>
          {publicRooms?.map(room => (
            <div key={room.id} className="flex justify-between items-center hover:font-bold cursor-pointer" onClick={() => handleJoinRoom(room.id)}>
              <div><strong>Join</strong> {room.id}</div>
              <div>players: {room.players.toLocaleString('en-US', { minimumIntegerDigits: 2 })}</div>
            </div>
          ))}
        </div>}
      </div>
    </div>
  );
};
