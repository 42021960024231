import { createContext, useContext } from "react";
import { Player, PublicRoom, Room } from "../api/firebase";
import { CreateRoomConfig, JoinRoomConfig, useGameRoom } from "./useGameRoom";
import { useLoadUser } from "./useLoadUser";
import { usePlayersList } from "./usePlayersList";
import { usePublicRooms } from "./usePublicRooms";

interface GameContextType {
  userId: string,
  name: string,
  saveName: (name: string) => void;
  room?: Room,
  players: Player[],
  sortPlayersForRound: (round: number) => Player[];
  sortPlayersForGame: (players: Player[]) => Required<Player>[];
  publicRooms: PublicRoom[],
  createRoom: (config: CreateRoomConfig) => void;
  joinRoom: (config: JoinRoomConfig) => void;
  leaveRoom: (userId: string) => void;
  startNextRound: (userId: string) => void;
  startNewGame: (userId: string) => void;
  verifyInput: (userId: string, input: string) => void;
  latestScore: number;
  latestGuess: string;
  loading: boolean;
  errorMessage: string;
}

let GameContext = createContext<GameContextType>(null!);

export const GameProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const { id, name, saveName } = useLoadUser();
  const { rooms: publicRooms } = usePublicRooms();
  const { room, createRoom, joinRoom, leaveRoom, startNextRound, startNewGame, verifyInput, latestScore, latestGuess, loading, errorMessage } = useGameRoom();
  const { players, sortPlayersForRound, sortPlayersForGame } = usePlayersList(room);

  const value: GameContextType = {
    userId: id,
    name,
    saveName,
    room,
    players,
    sortPlayersForRound,
    sortPlayersForGame,
    publicRooms,
    createRoom,
    joinRoom,
    leaveRoom,
    startNextRound,
    startNewGame,
    verifyInput,
    latestScore,
    latestGuess,
    loading,
    errorMessage
  }

  return (
    <GameContext.Provider value={value}>
      {children}
    </GameContext.Provider>
  );
}

export function useGame() {
  return useContext(GameContext);
}