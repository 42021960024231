import React from 'react';
import cx from 'classnames';

type CancelButtonProps = {
  buttonLabelClassNames?: string;
  classNames?: string;
  disabled?: boolean;
  label?: string;
  onClick?: () => void;
};

export const CancelButton: React.FC<CancelButtonProps> = ({
  classNames,
  buttonLabelClassNames,
  disabled,
  label,
  onClick,
}) => {
  const buttonClass = cx(
    'border-2 border-red-700 bg-red-300 hover:bg-red-600 disabled:hover:bg-red-100 hover:text-white disabled:bg-red-100 disabled:hover:bg-red-300 disabled:hover:text-black transition-all rounded-full px-2 py-2',
    classNames
  );

  return (
    <button disabled={disabled} onClick={onClick} className={buttonClass}>
      <span className={buttonLabelClassNames}>{label}</span>
    </button>
  );
};
