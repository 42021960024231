import { initializeApp } from "firebase/app";
import {
  ref,
  off,
  query,
  equalTo,
  onValue,
  orderByChild,
  getDatabase
} from "firebase/database";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

initializeApp(firebaseConfig);
const db = getDatabase();

export enum GameRoomSate {
  LOBBY = "lobby",
  ROUND_STARTED = "round_started",
  ROUND_ENDED = "round_ended",
  GAME_ENDED = "game_ended"
}

export type Guesses = {
  [round: number]: {
    score: number;
    input: string;
  };
};

export type Player = {
  id: string;
  name: string;
  guesses: Guesses;
  totalScore?: number;
};

export type PlayersList = {
  [id: string]: {
    name: string;
    guesses: Guesses;
  };
};

export type Room = {
  id: string;
  rounds: number;
  isPublic: boolean;
  owner: string;
  players: PlayersList;
  currentRound: number;
  gameState: GameRoomSate;
  roundEndTimestamp: number;
  imageUrl?: string;
  imageInput?: string;
};

export type PublicRoom = {
  id: string;
  players: number;
};

type PublicRoomsCallback = (rooms: PublicRoom[]) => void;
type RoomStateCallback = (room?: Room) => void;
type OwnerCallback = (owner: string) => void;

export function listPublicRooms(cb: PublicRoomsCallback) {
  const publicRoomsRef = query(
    ref(db, "rooms"),
    orderByChild("isPublic"),
    equalTo(true)
  );

  onValue(publicRoomsRef, (snapshot) => {
    const data: { [key: string]: Room } = snapshot.val();
    if (!data) {
      cb([]);
      return;
    }

    const rooms = Object.values(data)
      ?.filter((room) => room.players)
      ?.map((room) => ({
        id: room.id,
        players: room.players ? Object.keys(room.players).length : 0
      }));
    cb(rooms);
  });

  return function turnOff() {
    off(publicRoomsRef);
  };
}

export function getRoom(roomId: string, cb: RoomStateCallback) {
  const roomRef = ref(db, `rooms/${roomId}`);

  onValue(roomRef, (snapshot) => {
    const data: Room = snapshot.val();
    cb(data);
  });

  return function turnOff() {
    off(roomRef);
  };
}

export function getRoomOwner(roomId: string, cb: OwnerCallback) {
  const roomOwnerRef = ref(db, `rooms/${roomId}/owner`);

  onValue(roomOwnerRef, (snapshot) => {
    const owner: string = snapshot.val();
    cb(owner);
  });

  return function turnOff() {
    off(roomOwnerRef);
  };
}
