import { useCallback, useEffect, useState, useRef } from "react";
import { useParams, Navigate } from "react-router-dom";
import { CancelButton } from "./CancelButton";
import { ConfirmButton } from "./ConfirmButton";
import { Timer } from "./Timer";
import { Head } from './Head';
import { useGame } from "../hooks/useGame";
import { useCopyToClipboard } from '../hooks/useCopyToClipboard'
import { GameRoomSate, Player } from "../api/firebase";

type GameRouterParams = {
  roomId: string;
};

type PlayerWithPosition = Player & {
  position: number;
}

const getPlayerTag = (
  player: Player,
  ownerId: string,
  myId: string
): string => {
  const tags = [];
  if (player.id === ownerId) tags.push("Owner");
  if (player.id === myId) tags.push("Me");

  if (tags.length) {
    return `(${tags.join("/")}) `;
  }
  return "";
};

const getPlayerScoreFormatted = (score: number, suffix?: string): string => {
  score = Number.isNaN(score) ? 0 : score;
  return `${(score * 100).toFixed(2)}${suffix ?? ""}`;
};

const getPlayerPositionOrdinal = (index: number): string => {
  const position = index + 1;
  const ordinal =
    position === 1
      ? "st"
      : position === 2
        ? "nd"
        : position === 3
          ? "rd"
          : "th";
  return `${position}${ordinal}`;
};

export const Game: React.FC = () => {
  const { roomId } = useParams<GameRouterParams>();
  const urlRoomId = roomId;

  const copyToClipboard = useCopyToClipboard();
  const {
    userId,
    name: username,
    room,
    players,
    sortPlayersForRound,
    sortPlayersForGame,
    joinRoom,
    leaveRoom,
    startNextRound,
    startNewGame,
    verifyInput,
    latestGuess,
    latestScore,
    errorMessage,
  } = useGame();

  const amIAdmin = room?.owner === userId;

  const [joining, setJoining] = useState(false);
  const [leaving, setLeaving] = useState(false);
  const [joinFailed, setJoinFailed] = useState(false);
  const [showFinalScore, setShowFinalScore] = useState(false);
  const [sortedPlayers, setSortedPlayers] = useState<Player[]>([]);
  const [sortedPlayersForMobile, setSortedPlayersForMobile] = useState<PlayerWithPosition[]>([]);
  const [sortedLeaderBoard, setSortedLeaderBoard] = useState<Player[]>([]);

  const tooltipRef = useRef<HTMLDivElement>(null);
  const [guess, setGuess] = useState("");

  const buildMobileRoundScoresSorted = useCallback((sortedPlayers: Player[]): PlayerWithPosition[] => {
    let sorted = sortedPlayers.map<PlayerWithPosition>((player, index) => ({
      ...player,
      position: index
    }))

    const me = sorted.find(player => player.id === userId);
    sorted = sorted.slice(0, 3);
    if (me && me.position > 2) {
      sorted.pop()
      sorted.push(me)
    }

    return sorted
  }, [userId])

  useEffect(() => {
    if (room && room.currentRound >= 0) {
      const p = sortPlayersForRound(room.currentRound)
      setSortedPlayers(p);
      const mobilePlayers = buildMobileRoundScoresSorted(p);
      setSortedPlayersForMobile(mobilePlayers);
      setSortedLeaderBoard(sortPlayersForGame(players))
    }
  }, [players, sortPlayersForRound, sortPlayersForGame, room, room?.currentRound, buildMobileRoundScoresSorted])

  useEffect(() => {
    setGuess('');
    setShowFinalScore(false);
  }, [room?.currentRound])

  useEffect(() => {
    // Joined from the URL directly... Attempts joining the room of the link, otherwise
    // will fail and will be redirected to home with the error message on screen
    if (!room && urlRoomId && !joining && !leaving) {
      setJoining(true);
      joinRoom({ roomId: urlRoomId, userId, name: username })
      return;
    }

    // avoid running other things when moving to homepage...
    if (joining && errorMessage) {
      setJoinFailed(true)
      return;
    }
  }, [userId, joining, joinRoom, urlRoomId, username, room, errorMessage, joinFailed, leaving]);

  const playerScoreForRound = useCallback((userId: string): { score: string, input: string } => {
    const emptyScore = {
      input: '',
      score: getPlayerScoreFormatted(0, '%')
    }
    if (!room) return emptyScore;

    const playerScore = sortedPlayers.find(player => player.id === userId);
    if (!playerScore) return emptyScore;

    return {
      input: playerScore?.guesses?.[room.currentRound]?.input ?? '',
      score: getPlayerScoreFormatted(playerScore?.guesses?.[room.currentRound]?.score ?? 0, '%')
    }
  }, [sortedPlayers, room])

  const handleCopyToClipboard = useCallback(() => {
    copyToClipboard(window.location.href, () => {
      tooltipRef.current?.classList.remove("invisible");
      tooltipRef.current?.classList.remove("opacity-0");
      setTimeout(() => {
        tooltipRef.current?.classList.add("invisible");
        tooltipRef.current?.classList.add("opacity-0");
      }, 1500);
    })
  }, [copyToClipboard])

  const checkInput = useCallback(() => {
    verifyInput(userId, guess)
  }, [verifyInput, userId, guess]);

  const fillWithBestInput = useCallback(() => {
    const player = players.find(p => p.id === userId);
    if (player && room) {
      const input = player?.guesses?.[room.currentRound]?.input
      setGuess(input || guess);
    }
  }, [guess, room, players, userId]);

  const startGame = useCallback(() => {
    startNextRound(userId)
  }, [startNextRound, userId])

  const newGame = useCallback(() => {
    startNewGame(userId)
  }, [startNewGame, userId])

  const handleLeaveRoom = useCallback(() => {
    leaveRoom(userId);
    setLeaving(true);
  }, [leaveRoom, userId]);

  return (
    <div className="grid grid-cols-2 sm:px-4 lg:px-8 h-full">
      {/* Goes back to the homepage if leaving room */}
      {errorMessage || leaving ? <Navigate to="/" /> : null}

      {/* Lobby mode */}
      {room?.gameState === GameRoomSate.LOBBY && (
        <div className="col-span-2 flex grow flex-col bg-white shadow-sm rounded-md xs:p-4 sm:p-10 w-full sm:my-8">
          <div className="flex relative mb-8 justify-between grow-0">
            <span className="text-2xl font-medium flex items-center gap-x-2">
              <span>
                Room: {room.id}
              </span>
              <svg onClick={handleCopyToClipboard} version="1.1" id="share" xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 cursor-pointer"
                viewBox="0 0 227.216 227.216" >
                <path d="M175.897,141.476c-13.249,0-25.11,6.044-32.98,15.518l-51.194-29.066c1.592-4.48,2.467-9.297,2.467-14.317
	c0-5.019-0.875-9.836-2.467-14.316l51.19-29.073c7.869,9.477,19.732,15.523,32.982,15.523c23.634,0,42.862-19.235,42.862-42.879
	C218.759,19.229,199.531,0,175.897,0C152.26,0,133.03,19.229,133.03,42.865c0,5.02,0.874,9.838,2.467,14.319L84.304,86.258
	c-7.869-9.472-19.729-15.514-32.975-15.514c-23.64,0-42.873,19.229-42.873,42.866c0,23.636,19.233,42.865,42.873,42.865
	c13.246,0,25.105-6.042,32.974-15.513l51.194,29.067c-1.593,4.481-2.468,9.3-2.468,14.321c0,23.636,19.23,42.865,42.867,42.865
	c23.634,0,42.862-19.23,42.862-42.865C218.759,160.71,199.531,141.476,175.897,141.476z M175.897,15
	c15.363,0,27.862,12.5,27.862,27.865c0,15.373-12.499,27.879-27.862,27.879c-15.366,0-27.867-12.506-27.867-27.879
	C148.03,27.5,160.531,15,175.897,15z M51.33,141.476c-15.369,0-27.873-12.501-27.873-27.865c0-15.366,12.504-27.866,27.873-27.866
	c15.363,0,27.861,12.5,27.861,27.866C79.191,128.975,66.692,141.476,51.33,141.476z M175.897,212.216
	c-15.366,0-27.867-12.501-27.867-27.865c0-15.37,12.501-27.875,27.867-27.875c15.363,0,27.862,12.505,27.862,27.875
	C203.759,199.715,191.26,212.216,175.897,212.216z"/>
              </svg>
              <svg onClick={handleLeaveRoom} xmlns="http://www.w3.org/2000/svg" version="1.1" id="exit" viewBox="0 0 55 55" className="ml-2 w-6 h-6 cursor-pointer">
                <path d="M53.924,24.382c0.101-0.244,0.101-0.519,0-0.764c-0.051-0.123-0.125-0.234-0.217-0.327L41.708,11.293
                  c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414L50.587,23H29.001c-0.553,0-1,0.447-1,1s0.447,1,1,1h21.586L40.294,35.293
                  c-0.391,0.391-0.391,1.023,0,1.414C40.489,36.902,40.745,37,41.001,37s0.512-0.098,0.707-0.293l11.999-11.999
                  C53.799,24.616,53.873,24.505,53.924,24.382z"/>
                <path d="M36.001,29c-0.553,0-1,0.447-1,1v16h-10V8c0-0.436-0.282-0.821-0.697-0.953L8.442,2h26.559v16c0,0.553,0.447,1,1,1
                  s1-0.447,1-1V1c0-0.553-0.447-1-1-1h-34c-0.032,0-0.06,0.015-0.091,0.018C1.854,0.023,1.805,0.036,1.752,0.05
                  C1.658,0.075,1.574,0.109,1.493,0.158C1.467,0.174,1.436,0.174,1.411,0.192C1.38,0.215,1.356,0.244,1.328,0.269
                  c-0.017,0.016-0.035,0.03-0.051,0.047C1.201,0.398,1.139,0.489,1.093,0.589c-0.009,0.02-0.014,0.04-0.022,0.06
                  C1.029,0.761,1.001,0.878,1.001,1v46c0,0.125,0.029,0.243,0.072,0.355c0.014,0.037,0.035,0.068,0.053,0.103
                  c0.037,0.071,0.079,0.136,0.132,0.196c0.029,0.032,0.058,0.061,0.09,0.09c0.058,0.051,0.123,0.093,0.193,0.13
                  c0.037,0.02,0.071,0.041,0.111,0.056c0.017,0.006,0.03,0.018,0.047,0.024l22,7C23.797,54.984,23.899,55,24.001,55
                  c0.21,0,0.417-0.066,0.59-0.192c0.258-0.188,0.41-0.488,0.41-0.808v-6h11c0.553,0,1-0.447,1-1V30
                  C37.001,29.447,36.553,29,36.001,29z M23.001,52.633l-20-6.364V2.367l20,6.364V52.633z"/>
              </svg>

              <div ref={tooltipRef} className="inline-block absolute z-10 py-1 px-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip mt-12 ml-28">
                Link copied
                <div className="tooltip-arrow"></div>
              </div>
            </span>
            <div className="absolute xs:invisible sm:visible -top-2 right-0">
              <CancelButton onClick={handleLeaveRoom} label="Leave Room" />
            </div>
          </div>

          <div className="border-2 border-neutral-200 grow rounded-md xs:p-3 sm:p-6 flex flex-row flex-wrap xs:gap-2 sm:gap-4 overflow-auto content-start max-h-[60vh]">
            {sortedPlayers.map((player) => (
              <span
                key={player.id}
                className={`p-2 text-sm text-center rounded-full w-auto h-fit ${player.id === userId
                  ? "bg-orange-300"
                  : player.id === sortedPlayers[0].id
                    ? "bg-green-300"
                    : "bg-neutral-100"
                  }`}
              >
                {getPlayerTag(player, room.owner, userId)}
                {player.name}
              </span>
            ))}
          </div>
          <div className="grow-0 mt-4 text-center">
            {sortedPlayers?.length} Player(s) in the room!
          </div>
          {amIAdmin ? (
            <div className="grow-0 mt-4">
              <ConfirmButton
                onClick={startGame}
                label={`${room.rounds} rounds. Start Guessing!`}
                classNames="w-full"
              />
            </div>
          ) : (
            <div className="grow-0 mt-4 text-center">
              Waiting for the Room owner to start the game
            </div>
          )}
        </div>
      )}

      {/* In-Game mode */}
      {room?.gameState === GameRoomSate.ROUND_STARTED && (
        <div className="col-span-2 grid grid-cols-5 w-full gap-x-2 sm:px-4 sm:gap-x-8 lg:px-8 sm:my-8">
          <div className="xs:col-span-5 sm:col-span-3 lg:col-span-3 flex flex-col bg-white shadow-sm rounded-md px-3 pb-3 pt-1 gap-y-1.5">
            <div
              className="flex-grow-1 max-h-[95vw]"
              style={{
                backgroundImage: `url(${room?.imageUrl})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                backgroundPosition: "center"
              }}
            />
            <div className="xs:grow sm:hidden max-h-[150px]">
              <div className="grow-0 mb-1 text-sm text-center">
                Round {room.currentRound}/{room.rounds} - Best Scores
              </div>
              <div className="flex flex-col gap-y-1 grow">
                {sortedPlayersForMobile.map((player) => {
                  return (
                    <div
                      key={player.id}
                      className={`flex flex-row flex-nowrap whitespace-nowrap gap-x-1 justify-between items-center ${player.name === username &&
                        "p-1 bg-orange-100 rounded-md"
                        }`}
                    >
                      <div>
                        <span
                          className={`bg-neutral-200 bg-neu py-1 px-2 rounded-full bg- ${player.position === 0 && "bg-amber-400"
                            } ${player.position === 1 && "bg-neutral-400 text-white"} ${player.position === 2 && "bg-yellow-700 text-white"
                            }`}
                        >
                          {getPlayerPositionOrdinal(player.position)}
                        </span>
                        <span className="p-1">
                          {getPlayerScoreFormatted(player?.guesses?.[room?.currentRound]?.score ?? 0, "%")}
                        </span>
                      </div>
                      <span className="bg-neutral-200 py-1 px-2 rounded-full shrink overflow-hidden text-ellipsis">
                        {getPlayerTag(player, room.owner, userId)}{" "}
                        {player.name}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
            <Timer
              endTimestamp={room.roundEndTimestamp}
              classNames="text-end text-base text-red-500 font-bold sm:hidden"
            />
            <div className="flex flex-row gap-x-2 items-center xs:text-sm sm:text-base xs:justify-between sm:justify-start">
              <strong>Best ({playerScoreForRound(userId).score}):</strong><span className="xs:text-end sm:text-start">{playerScoreForRound(userId).input}</span>
            </div>
            <div className="flex flex-row gap-x-4 items-center">
              <input
                className="border-2 rounded-md p-2 w-full"
                placeholder="Type your guess here"
                value={guess}
                onChange={(e) => setGuess(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") checkInput();
                  if (e.key === "ArrowUp") fillWithBestInput();
                }}
              />
              <ConfirmButton
                onClick={checkInput}
                classNames="px-6"
                label="Guess!"
              />
            </div>
            <div className="flex flex-row gap-x-2 items-center xs:text-sm sm:text-base xs:justify-between sm:justify-start">
              <strong>Current ({getPlayerScoreFormatted(latestScore, '%')}):</strong><span className="xs:text-end sm:text-start">{latestGuess}</span>
            </div>
          </div>
          <div className="xs:hidden xs:right-4 xs:top-3 sm:static sm:col-span-2 lg:col-span-2 sm:flex flex-col bg-white xs:shadow-xl sm:shadow-sm rounded-md p-2 pb-0 shrink-0">
            <div className="xs:text-xl sm:text-2xl font-medium self-center flex items-center gap-x-2">
              Room: {room.id}
              <svg onClick={handleCopyToClipboard} version="1.1" id="share" xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 cursor-pointer"
                viewBox="0 0 227.216 227.216" >
                <path d="M175.897,141.476c-13.249,0-25.11,6.044-32.98,15.518l-51.194-29.066c1.592-4.48,2.467-9.297,2.467-14.317
	c0-5.019-0.875-9.836-2.467-14.316l51.19-29.073c7.869,9.477,19.732,15.523,32.982,15.523c23.634,0,42.862-19.235,42.862-42.879
	C218.759,19.229,199.531,0,175.897,0C152.26,0,133.03,19.229,133.03,42.865c0,5.02,0.874,9.838,2.467,14.319L84.304,86.258
	c-7.869-9.472-19.729-15.514-32.975-15.514c-23.64,0-42.873,19.229-42.873,42.866c0,23.636,19.233,42.865,42.873,42.865
	c13.246,0,25.105-6.042,32.974-15.513l51.194,29.067c-1.593,4.481-2.468,9.3-2.468,14.321c0,23.636,19.23,42.865,42.867,42.865
	c23.634,0,42.862-19.23,42.862-42.865C218.759,160.71,199.531,141.476,175.897,141.476z M175.897,15
	c15.363,0,27.862,12.5,27.862,27.865c0,15.373-12.499,27.879-27.862,27.879c-15.366,0-27.867-12.506-27.867-27.879
	C148.03,27.5,160.531,15,175.897,15z M51.33,141.476c-15.369,0-27.873-12.501-27.873-27.865c0-15.366,12.504-27.866,27.873-27.866
	c15.363,0,27.861,12.5,27.861,27.866C79.191,128.975,66.692,141.476,51.33,141.476z M175.897,212.216
	c-15.366,0-27.867-12.501-27.867-27.865c0-15.37,12.501-27.875,27.867-27.875c15.363,0,27.862,12.505,27.862,27.875
	C203.759,199.715,191.26,212.216,175.897,212.216z"/>
              </svg>
              <svg onClick={handleLeaveRoom} xmlns="http://www.w3.org/2000/svg" version="1.1" id="exit" viewBox="0 0 55 55" className="ml-2 w-6 h-6 cursor-pointer">
                <path d="M53.924,24.382c0.101-0.244,0.101-0.519,0-0.764c-0.051-0.123-0.125-0.234-0.217-0.327L41.708,11.293
                  c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414L50.587,23H29.001c-0.553,0-1,0.447-1,1s0.447,1,1,1h21.586L40.294,35.293
                  c-0.391,0.391-0.391,1.023,0,1.414C40.489,36.902,40.745,37,41.001,37s0.512-0.098,0.707-0.293l11.999-11.999
                  C53.799,24.616,53.873,24.505,53.924,24.382z"/>
                <path d="M36.001,29c-0.553,0-1,0.447-1,1v16h-10V8c0-0.436-0.282-0.821-0.697-0.953L8.442,2h26.559v16c0,0.553,0.447,1,1,1
                  s1-0.447,1-1V1c0-0.553-0.447-1-1-1h-34c-0.032,0-0.06,0.015-0.091,0.018C1.854,0.023,1.805,0.036,1.752,0.05
                  C1.658,0.075,1.574,0.109,1.493,0.158C1.467,0.174,1.436,0.174,1.411,0.192C1.38,0.215,1.356,0.244,1.328,0.269
                  c-0.017,0.016-0.035,0.03-0.051,0.047C1.201,0.398,1.139,0.489,1.093,0.589c-0.009,0.02-0.014,0.04-0.022,0.06
                  C1.029,0.761,1.001,0.878,1.001,1v46c0,0.125,0.029,0.243,0.072,0.355c0.014,0.037,0.035,0.068,0.053,0.103
                  c0.037,0.071,0.079,0.136,0.132,0.196c0.029,0.032,0.058,0.061,0.09,0.09c0.058,0.051,0.123,0.093,0.193,0.13
                  c0.037,0.02,0.071,0.041,0.111,0.056c0.017,0.006,0.03,0.018,0.047,0.024l22,7C23.797,54.984,23.899,55,24.001,55
                  c0.21,0,0.417-0.066,0.59-0.192c0.258-0.188,0.41-0.488,0.41-0.808v-6h11c0.553,0,1-0.447,1-1V30
                  C37.001,29.447,36.553,29,36.001,29z M23.001,52.633l-20-6.364V2.367l20,6.364V52.633z"/>
              </svg>
              <div ref={tooltipRef} className="inline-block absolute z-10 py-1 px-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip dark:bg-gray-700">
                Link copied
                <div className="tooltip-arrow" data-popper-arrow></div>
              </div>
            </div>
            <div className="grow-0 shrink-0">
              <Timer
                endTimestamp={room.roundEndTimestamp}
                classNames="text-center xs:text-2xl sm:text-4xl text-red-500 font-bold my-2"
              />
            </div>
            <div className="xs:hidden sm:flex flex-col grow shrink h-[69vh]">
              <div className="grow-0 mb-2 text-lg text-center border-b-2">
                Round {room.currentRound}/{room.rounds} - Best Scores
              </div>
              <div className="flex flex-col gap-y-1 grow overflow-y-auto overflow-x-hidden">
                {sortedPlayers.map((player, index) => {
                  return (
                    <div
                      key={player.id}
                      className={`flex flex-row flex-nowrap whitespace-nowrap gap-x-1 justify-between items-center ${player.name === username &&
                        "p-1 bg-orange-100 rounded-md"
                        }`}
                    >
                      <div>
                        <span
                          className={`bg-neutral-200 bg-neu py-1 px-2 rounded-full bg- ${index === 0 && "bg-amber-400"
                            } ${index === 1 && "bg-neutral-400 text-white"} ${index === 2 && "bg-yellow-700 text-white"
                            }`}
                        >
                          {getPlayerPositionOrdinal(index)}
                        </span>
                        <span className="p-1">
                          {getPlayerScoreFormatted(player?.guesses?.[room.currentRound]?.score ?? 0, "%")}
                        </span>
                      </div>
                      <span className="bg-neutral-200 py-1 px-2 rounded-full shrink overflow-hidden text-ellipsis">
                        {getPlayerTag(player, room.owner, userId)}{" "}
                        {player.name}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Leaderboard mode */}
      {(room?.gameState === GameRoomSate.GAME_ENDED || room?.gameState === GameRoomSate.ROUND_ENDED) && !showFinalScore && (
        <div className="col-span-2 flex flex-col bg-white shadow-sm rounded-md p-3 pt-1 w-full sm:px-16 sm:my-8">
          <div className="w-full text-2xl sm:mb-4 font-medium self-center flex items-center gap-2 justify-center">
            <Head />
            <div className="ml-8 text-base sm:text-xl leading-5 flex gap-2 items-center">
              Room: {room.id}
              <svg onClick={handleCopyToClipboard} version="1.1" id="share" xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 cursor-pointer"
                viewBox="0 0 227.216 227.216" >
                <path d="M175.897,141.476c-13.249,0-25.11,6.044-32.98,15.518l-51.194-29.066c1.592-4.48,2.467-9.297,2.467-14.317
    c0-5.019-0.875-9.836-2.467-14.316l51.19-29.073c7.869,9.477,19.732,15.523,32.982,15.523c23.634,0,42.862-19.235,42.862-42.879
    C218.759,19.229,199.531,0,175.897,0C152.26,0,133.03,19.229,133.03,42.865c0,5.02,0.874,9.838,2.467,14.319L84.304,86.258
    c-7.869-9.472-19.729-15.514-32.975-15.514c-23.64,0-42.873,19.229-42.873,42.866c0,23.636,19.233,42.865,42.873,42.865
    c13.246,0,25.105-6.042,32.974-15.513l51.194,29.067c-1.593,4.481-2.468,9.3-2.468,14.321c0,23.636,19.23,42.865,42.867,42.865
    c23.634,0,42.862-19.23,42.862-42.865C218.759,160.71,199.531,141.476,175.897,141.476z M175.897,15
    c15.363,0,27.862,12.5,27.862,27.865c0,15.373-12.499,27.879-27.862,27.879c-15.366,0-27.867-12.506-27.867-27.879
    C148.03,27.5,160.531,15,175.897,15z M51.33,141.476c-15.369,0-27.873-12.501-27.873-27.865c0-15.366,12.504-27.866,27.873-27.866
    c15.363,0,27.861,12.5,27.861,27.866C79.191,128.975,66.692,141.476,51.33,141.476z M175.897,212.216
    c-15.366,0-27.867-12.501-27.867-27.865c0-15.37,12.501-27.875,27.867-27.875c15.363,0,27.862,12.505,27.862,27.875
    C203.759,199.715,191.26,212.216,175.897,212.216z"/>
              </svg>
              <svg onClick={handleLeaveRoom} xmlns="http://www.w3.org/2000/svg" version="1.1" id="exit" viewBox="0 0 55 55" className="ml-2 w-6 h-6 cursor-pointer">
                <path d="M53.924,24.382c0.101-0.244,0.101-0.519,0-0.764c-0.051-0.123-0.125-0.234-0.217-0.327L41.708,11.293
                  c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414L50.587,23H29.001c-0.553,0-1,0.447-1,1s0.447,1,1,1h21.586L40.294,35.293
                  c-0.391,0.391-0.391,1.023,0,1.414C40.489,36.902,40.745,37,41.001,37s0.512-0.098,0.707-0.293l11.999-11.999
                  C53.799,24.616,53.873,24.505,53.924,24.382z"/>
                <path d="M36.001,29c-0.553,0-1,0.447-1,1v16h-10V8c0-0.436-0.282-0.821-0.697-0.953L8.442,2h26.559v16c0,0.553,0.447,1,1,1
                  s1-0.447,1-1V1c0-0.553-0.447-1-1-1h-34c-0.032,0-0.06,0.015-0.091,0.018C1.854,0.023,1.805,0.036,1.752,0.05
                  C1.658,0.075,1.574,0.109,1.493,0.158C1.467,0.174,1.436,0.174,1.411,0.192C1.38,0.215,1.356,0.244,1.328,0.269
                  c-0.017,0.016-0.035,0.03-0.051,0.047C1.201,0.398,1.139,0.489,1.093,0.589c-0.009,0.02-0.014,0.04-0.022,0.06
                  C1.029,0.761,1.001,0.878,1.001,1v46c0,0.125,0.029,0.243,0.072,0.355c0.014,0.037,0.035,0.068,0.053,0.103
                  c0.037,0.071,0.079,0.136,0.132,0.196c0.029,0.032,0.058,0.061,0.09,0.09c0.058,0.051,0.123,0.093,0.193,0.13
                  c0.037,0.02,0.071,0.041,0.111,0.056c0.017,0.006,0.03,0.018,0.047,0.024l22,7C23.797,54.984,23.899,55,24.001,55
                  c0.21,0,0.417-0.066,0.59-0.192c0.258-0.188,0.41-0.488,0.41-0.808v-6h11c0.553,0,1-0.447,1-1V30
                  C37.001,29.447,36.553,29,36.001,29z M23.001,52.633l-20-6.364V2.367l20,6.364V52.633z"/>
              </svg>
              <div ref={tooltipRef} className="inline-block absolute z-10 py-1 px-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip dark:bg-gray-700 mt-12 ml-12">
                Link copied
                <div className="tooltip-arrow" data-popper-arrow></div>
              </div>
            </div>
          </div>
          <div className="xs:contents sm:flex flex-row gap-x-8 h-full">
            <div className="flex flex-col grow">
              <div
                className="grow"
                style={{
                  backgroundImage: `url(${room?.imageUrl})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  backgroundPosition: "center"
                }}
              />
              <div className="xs:grow sm:hidden max-h-[30%] mt-2">
                <div className="flex flex-col gap-y-1 grow overflow-y-auto overflow-x-hidden max-h-[75%]">
                  {sortedPlayers.map((player, index) => {
                    return (
                      <div key={player.id}>
                        <div
                          className={`flex flex-row flex-nowrap whitespace-nowrap gap-x-1 justify-between items-center ${player.name === username &&
                            "p-1 bg-orange-100 rounded-md"
                            }`}
                        >
                          <div>
                            <span
                              className={`bg-neutral-200 bg-neu py-1 px-2 rounded-full bg- ${index === 0 && "bg-amber-400"
                                } ${index === 1 && "bg-neutral-400 text-white"} ${index === 2 && "bg-yellow-700 text-white"
                                }`}
                            >
                              {getPlayerPositionOrdinal(index)}
                            </span>
                            <span className="p-1">
                              {getPlayerScoreFormatted(player.guesses[room.currentRound].score, "%")}
                            </span>
                          </div>
                          <span className="bg-neutral-200 py-1 px-2 rounded-full shrink overflow-hidden text-ellipsis">
                            {getPlayerTag(player, room.owner, userId)}{" "}
                            {player.name}
                          </span>
                        </div>
                        {player.guesses[room.currentRound].input && <div className="text-sm">
                          "<i>{player.guesses[room.currentRound].input}</i>"
                        </div>}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="grow-0 shrink-0">
                Correct Prompt: "<i>{room?.imageInput}</i>"
              </div>
              {/* <a href={`https://www.badbotdesign.com/purchase/${roundGameGuess.imageId}`} target="_blank" rel="noreferrer">
                <strong>Buy shirt here</strong>
              </a> */}
            </div>
            <div className="xs:hidden sm:flex flex-col w-2/5 h-[65vh]">
              <div className="flex flex-col gap-y-2 grow overflow-y-auto overflow-x-hidden">
                {sortedPlayers.map((player, index) => {
                  return (
                    <div key={player.id}>
                      <div
                        className={`flex flex-row flex-nowrap whitespace-nowrap gap-x-1 justify-between items-center ${player.name === username &&
                          "p-1 bg-orange-100 rounded-md"
                          }`}
                      >
                        <div>
                          <span
                            className={`bg-neutral-200 bg-neu py-1 px-2 rounded-full bg- ${index === 0 && "bg-amber-400"
                              } ${index === 1 && "bg-neutral-400 text-white"} ${index === 2 && "bg-yellow-700 text-white"
                              }`}
                          >
                            {getPlayerPositionOrdinal(index)}
                          </span>
                          <span className="p-1">
                            {getPlayerScoreFormatted(player.guesses[room.currentRound].score, "%")}
                          </span>
                        </div>
                        <span className="bg-neutral-200 py-1 px-2 rounded-full shrink overflow-hidden text-ellipsis">
                          {getPlayerTag(player, room.owner, userId)}{" "}
                          {player.name}
                        </span>
                      </div>
                      {player.guesses[room.currentRound].input && <div className="text-sm">
                        "<i>{player.guesses[room.currentRound].input}</i>"
                      </div>}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="mt-4">
            {(amIAdmin || room?.currentRound === room?.rounds) ? (
              <div>
                <ConfirmButton
                  onClick={() => (room?.currentRound === room?.rounds ? setShowFinalScore(true) : startGame())}
                  label={room?.currentRound === room?.rounds ? "See final score!" : "Start next round"}
                  classNames="w-full"
                />
              </div>
            ) : (
              <div className="text-center">
                Waiting for the room owner to{" "}
                {room?.currentRound === room?.rounds ? "close the game..." : "start the next round..."}
              </div>
            )}
          </div>
        </div>
      )}
      {room?.gameState === GameRoomSate.GAME_ENDED && showFinalScore && (
        <div className="col-span-2 flex flex-col bg-white shadow-sm rounded-md p-6 w-full sm:px-16 sm:my-8">
          <div className="flex flex-col gap-y-2 grow overflow-y-auto overflow-x-hidden">
            {sortedLeaderBoard.map((player, index) => {
              return (
                <div key={player.id}>
                  <div
                    className={`flex flex-row flex-nowrap whitespace-nowrap gap-x-1 justify-between items-center ${player.name === username &&
                      "p-1 bg-orange-100 rounded-md"
                      }`}
                  >
                    <div>
                      <span
                        className={`bg-neutral-200 bg-neu py-1 px-2 rounded-full bg- ${index === 0 && "bg-amber-400"
                          } ${index === 1 && "bg-neutral-400 text-white"} ${index === 2 && "bg-yellow-700 text-white"
                          }`}
                      >
                        {getPlayerPositionOrdinal(index)}
                      </span>
                      <span className="p-1">
                        {getPlayerScoreFormatted(player?.totalScore ?? 0, `/${room.rounds * 100}`)}
                      </span>
                    </div>
                    <span className="bg-neutral-200 py-1 px-2 rounded-full shrink overflow-hidden text-ellipsis">
                      {getPlayerTag(player, room.owner, userId)}{" "}
                      {player.name}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="mt-4">
            {amIAdmin ? (
              <div>
                <ConfirmButton
                  onClick={newGame}
                  label="Start new game!"
                  classNames="w-full"
                />
              </div>
            ) : (
              <div className="text-center">
                Waiting for the room owner to close or start a new game
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
