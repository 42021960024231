import React from 'react';

export const Footer: React.FC = () => {
  return (
    <footer className="text-sm flex justify-center grow-0 py-1.5">
      <span>
        Made with <span className='text-red-500'>♥</span> by{" "}
        <a className='hover:underline' href="https://ae.studio?utm_source=sds&utm_medium=referral&utm_campaign=prompttheory&utm_content=footer&utm_term=3ff5251a-e107-4d47-bfb8-b2962debd252" target="_blank" rel="noreferrer">
          <strong>AE Studio</strong>
        </a>
        {" "}and{" "}
        <a className='hover:underline' href="https://ae.studio/same-day-skunkworks?utm_source=sds&utm_medium=referral&utm_campaign=prompttheory&utm_content=footer&utm_term=3ff5251a-e107-4d47-bfb8-b2962debd252" target="_blank" rel="noreferrer">
          <strong>SDS</strong>
        </a>
      </span>
    </footer>
  );
};
