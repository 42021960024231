import { nameByRace, RaceType } from "fantasy-name-generator";
import { useCallback, useState } from "react";

const races: RaceType[] = [
  "cavePerson",
  "dwarf",
  "halfling",
  "gnome",
  "elf",
  "highelf",
  "fairy",
  "highfairy",
  "darkelf",
  "drow",
  "halfdemon",
  "dragon",
  "angel",
  "demon",
  "goblin",
  "orc",
  "ogre",
  "human"
];
function getRandomName(): string {
  const index = Math.floor(Math.random() * (races.length - 0 + 1) + 0);
  const isMale = Math.random() < 0.5;
  const name = nameByRace(races[index], { gender: isMale ? "male" : "female" });
  if (name instanceof Error) return getRandomName();

  return name;
}

export function useRandomName() {
  const [name, setName] = useState(getRandomName());

  const changeName = useCallback(() => {
    setName(getRandomName());
  }, []);

  return { name, changeName };
}
