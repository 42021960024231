import axios from "axios";

import { useCallback, useEffect, useState } from "react";
import { getRoom, getRoomOwner, Room } from "../api/firebase";

const url = process.env.REACT_APP_SERVER_URL || "http://localhost:3003";

export type JoinRoomConfig = {
  userId: string;
  name: string;
  roomId: string;
};

export type CreateRoomConfig = {
  userId: string;
  name: string;
  rounds: number;
  isPublic: boolean;
};

export function useGameRoom() {
  const [id, setId] = useState<string>("");
  const [latestGuess, setLatestGuess] = useState("");
  const [latestScore, setLatestScore] = useState(0);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [room, setRoom] = useState<Room>();

  const createRoom = useCallback((config: CreateRoomConfig) => {
    setLoading(true);
    axios
      .post(url + `/room`, config)
      .then((response) => {
        const { data } = response;
        setId(data.roomId);
        setLoading(false);
        setErrorMessage("");
      })
      .catch(() => setErrorMessage("Error creating room"));
  }, []);

  const joinRoom = useCallback((config: JoinRoomConfig) => {
    setLoading(true);
    axios
      .post(url + `/room/${config.roomId}/join`, {
        userId: config.userId,
        name: config.name
      })
      .then((response) => {
        const { data } = response;
        setId(data.roomId);
        setLoading(false);
        setErrorMessage("");
      })
      .catch(() => setErrorMessage("Error joining room"));
  }, []);

  const leaveRoom = useCallback(
    (userId: string, timeout?: number) => {
      if (!room) return;

      setId("");
      setRoom(undefined);
      setLoading(true);
      axios
        .post(url + `/room/${room.id}/leave`, { userId, timeout })
        .finally(() => {
          setErrorMessage("");
          setLoading(false);
        });
    },
    [room]
  );

  const startNextRound = useCallback(
    (userId: string) => {
      if (!room) return;

      axios
        .post(url + `/room/${room.id}/nextRound`, { userId })
        .then(() => {
          setLoading(false);
          setErrorMessage("");
        })
        .catch(() => setErrorMessage("Error starting round"));
    },
    [room]
  );

  const startNewGame = useCallback(
    (userId: string) => {
      if (!room) return;

      axios
        .post(url + `/room/${room.id}/newGame`, { userId })
        .then(() => {
          setLoading(false);
          setErrorMessage("");
        })
        .catch(() => setErrorMessage("Error starting new game"));
    },
    [room]
  );

  const verifyInput = useCallback(
    (userId: string, input: string) => {
      if (!room) return;

      axios
        .post(url + `/room/${room.id}/verifyInput`, { userId, input })
        .then(({ data }) => {
          setLatestScore(data.score);
          setLatestGuess(input);
          setLoading(false);
          setErrorMessage("");
        })
        .catch(() => setErrorMessage("Error starting round"));
    },
    [room]
  );

  useEffect(() => {
    setLatestScore(0);
    setLatestGuess("");
  }, [room?.currentRound]);

  useEffect(() => {
    if (!id) return;

    setLoading(true);
    const turnOffRoom = getRoom(id, (room) => {
      if (!room) {
        setErrorMessage("Room not found");
      } else {
        setErrorMessage("");
        setRoom(room);
      }
      setLoading(false);
    });
    const turnOffRoomOwner = getRoomOwner(id, (owner) => {
      setLoading(false);
      setRoom((state) => {
        if (state) {
          return {
            ...state,
            owner
          };
        }
      });
    });
    return () => {
      turnOffRoom();
      turnOffRoomOwner();
    };
  }, [id]);

  return {
    room,
    createRoom,
    joinRoom,
    leaveRoom,
    startNextRound,
    startNewGame,
    verifyInput,
    latestScore,
    latestGuess,
    loading,
    errorMessage
  };
}
