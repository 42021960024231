import React from 'react';
import cx from 'classnames';

type ConfirmButtonProps = {
  buttonLabelClassNames?: string;
  classNames?: string;
  disabled?: boolean;
  label?: string | React.ReactNode;
  onClick?: () => void;
};

export const ConfirmButton: React.FC<ConfirmButtonProps> = ({
  classNames,
  buttonLabelClassNames,
  disabled,
  label,
  onClick,
}) => {
  const buttonClass = cx(
    'border-2 border-green-700 bg-green-300 hover:bg-green-600 disabled:hover:bg-green-100 hover:text-white disabled:bg-green-100 disabled:hover:bg-green-300 disabled:hover:text-black transition-all rounded-full px-2 py-2',
    { 'cursor-pointer': !disabled },
    classNames
  );

  return (
    <button disabled={disabled} onClick={onClick} className={buttonClass}>
      <span className={buttonLabelClassNames}>{label}</span>
    </button>
  );
};
